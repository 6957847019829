<template>
<div class="p7 de">
  <div class="hd">
    <span @click="$router.back()">返回</span>
    <div class="action">
      <div>
<!--        <i class="iconfont icon-shoucang"></i> 收藏状态-->
      </div>
      <div style="display: none">
        <md-icon name="share" />
        分享
      </div>
    </div>

  </div>

    <div class="ibox">
      <md-scroll-view auto-reflow :scrolling-x="false">
        <div class="ibh">
          <div class="ibhi">
            <img :src="bookInfo.cover+'-zbmobile'" alt="" style="height: 100%;width: 100%;object-fit: cover">
<!--            <i class="iconfont icon-bofang dm" id="bofang"></i>-->
<!--            <i class="iconfont icon-fangda "></i>-->
          </div>

          <div class="ibhf">
            <div class="ibhft ft3">{{ bookInfo.title }}</div>
<!--            <div class="ibhfb">
              <div class="ft5 lt">{{activity.addTime|format('YYYY-MM-DD HH:mm')}} 发布</div>
              <div class="ft5 rt">
                <i class="iconfont icon-ai-eye " ></i> {{ activity.reading }}
              </div>
            </div>-->
          </div>
          <md-field>
            <md-detail-item title="类别" :content="bookInfo.type|bookTypeStr"  />
            <md-detail-item title="地址" :content="bookInfo.address" />
            <md-detail-item title="容纳人数" :content="bookInfo.peoples" />

          </md-field>
        </div>

        <div class="ibi">
          <div class="ibih">
            <div class="ft2 lt" >简介</div>
          </div>
          <div class="ibii" id="ctct" v-html="bookInfo.desc">

          </div>

        </div>
        <md-field>
          <md-input-item
              ref="input0"
              title="单位名称"
              placeholder="请填写"
              align="right"
              v-model="book.name"
          />
          <md-cell-item title="单位性质" >
            <div class="holder" slot="right">
              <md-radio-box name="1" v-model="book.xingzhi" label="机关"   style="margin-right: 5px;"/>
              <md-radio-box name="2" v-model="book.xingzhi"  label="事业" style="margin-right: 5px;"/>
              <md-radio-box name="3" v-model="book.xingzhi" label="企业" style="margin-right: 5px;"/>
              <md-radio-box name="4" v-model="book.xingzhi" label="社会团体" />
            </div>
          </md-cell-item>
          <md-cell-item title="用途"   >
            <div class="holder" slot="right">
              <md-radio-box name="1" v-model="book.gongyi" label="公益"   style="margin-right: 5px;"/>
              <md-radio-box name="2" v-model="book.gongyi"  label="非公益" /></div>
          </md-cell-item>

          <md-input-item
              ref="input0"
              title="联系人"
              placeholder="请填写联系人姓名"
              :maxlength="8"
              align="right"
              v-model="book.lxname"
          />
          <md-input-item
              ref="input0"
              title="身份证"
              placeholder="请填写联系人身份证信息"
              align="right"
              v-model="book.lxid"
          />
          <md-input-item
              ref="input0"
              title="电话"
              placeholder="请填写联系电话"
              type="phone"
              align="right"
              v-model="book.lxphone"
          />

          <md-textarea-item
              title="活动内容"
              placeholder="请填写活动内容"
              :row="5"
              align="right"
              v-model="book.ct"
          />


          <md-cell-item title="日期"   >
            <div class="holder" slot="right" style="display: flex">
                <div class="ip"> 8:30-12:00 </div>
                <div class="ip"> 14:30-17:30 </div>
            </div>
          </md-cell-item>
          <md-cell-item :title="item.yd+ item.xq"  v-for="item of paiban" >
            <div class="holder" slot="right">
              <md-radio-box class="ip" :name="item.xq+'_'+'上午'" v-model="theday" :disabled="!item.sw || item.useSw"  :label="`${ item.useSw ? '被预约':'上午'}` "   style="margin-right: 20px;"/>
              <md-radio-box  class="ip" :name="item.xq+'_'+'下午'" v-model="theday" :disabled="!item.xw || item.useXw" :label="`${ item.useXw ? '被预约':'下午'}`" />
            </div>
          </md-cell-item>
        </md-field>

      </md-scroll-view>


    </div>
    <div class="bbox">
          <div @click="addBook"> 立即预约</div>
    </div>


  <md-picker
      ref="picker1"
      v-model="showArea"
      :data="areaData"
      :cols="3"
      is-cascade
      large-radius
      title="选择省市区/县"
      @confirm="ondAreaConfirm"
  ></md-picker>
</div>
</template>
<script>
import bookList from "@/views/bookList";

const  data  = require('province-city-china/dist/level.json')
import district from 'mand-mobile/components/picker/demo/data/district'
console.log(district)
export default {
    name: "acDetail",
    data(){
      return {
        theday:null,
        showArea:false,
        areaStr:'',
        activity:{},
        options:[],
        optarr:[],
        areaData:[],
        detailAdd:'',
        bookInfo:null,
        originPb:[],
        paiban:[],
        book:{
          gongyi:'',
          xingzhi:'',
          name:'',
          lxname:'',
          lxphone:'',
          ct:'',
          lxid:''
        }
      }
    },
  created() {
     this.getData()
     data.forEach(item=>{

              item.labeil = item.name;
              item.value = item.name;
              item.text = item.name;

              item.children.forEach(ite1 => {
                ite1.labeil = ite1.name;
                ite1.value = ite1.name;
                ite1.text = ite1.name;
                if(ite1.children) {
                    ite1.children.forEach( ite2=>{
                      ite2.labeil = ite1.name;
                      ite2.value = ite2.name;
                      ite2.text = ite2.name;
                    })
                }
              })


    })
     this.areaData =[ data ];
     console.log('kkkk',this.areaData)
  },
  watch:{
    theday(nv,ov){

        console.log(nv)
      }
  },

  methods:{
    async addBook(){
        const { uid,state  } = this.$store.state.userInfo;

        const { lxname,name,lxphone,ct,lxid,xingzhi,gongyi} = this.book;

        if(!name) return this.$toast.info('请填写单位名称');
        if(!xingzhi) return this.$toast.info('请填写单位性质');
        if(!gongyi) return this.$toast.info('请填写用途');
        if(!lxname) return  this.$toast.info('请填写联系人姓名');
        if(!lxid) return this.$toast.info('请填写身份证信息');
        if(!lxphone) return this.$toast.info('请填写联系电话');
        if(!ct) return this.$toast.info('请填写活动内容');

        //手机号正则
        const zhengze = /^1[3456789]\d{9}$/
        if(!zhengze.test(lxphone)) return this.$toast.failed('请填写正确的手机号码！')
        //身份证号正则
        const p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (!p.test(lxid)) return this.$toast.info('请填写正确的身份证信息');

        if(!uid) return this.$toast.info('需要先登录')
        // if(!state || state!=1 ) return this.$toast.info('需要填通过实名后方可预约')
        if(!this.theday) return this.$toast.info('请选择要约定的日期')
            const xq  =  this.theday.split('_')[0];
            const sx  =  this.theday.split('_')[1];

          const postData = { ...this.book}

          let _bt =  new Date(this.paiban.find(r=>r.xq === xq)._d)
          _bt.setHours(0,0,0,0);
          postData.bookTime = _bt.getTime()

          postData.sx = sx
          postData.uid = this.$store.state.userInfo.uid || 62;
          postData.bookId = this.$route.query.id;
          let res = await this.$api.post('/mapi/booking',postData)
          const { success ,data,info} = res.data;
          if(success){
            this.book = {}  //清空表单
            this.theday = null
            return  this.$toast.succeed('提交成功，请等待审核')
          }
          return this.$toast.info(info)
    },
    ondAreaConfirm(columnsValue){
        let resStr = ''
         for(const item of columnsValue){
           if(!item) continue;
           resStr = resStr +item.text+'/'
         }
         this.areaStr = resStr.slice(0,resStr.length-1)
    },
        async getData(){
          const id = this.$route.query.id
          const res = await this.$api.post('/mapi/GetOnebook',{id})
          const { success,data,days } = res.data
          this.bookInfo = data
          this.$nextTick(()=>{
                let dimg = document.querySelectorAll("#ctct img");

                for(let item of dimg){
                  item.src = item.src + '-zbmobile'
                }
          })
          this.originPb = JSON.parse(data.paiban)
          this.paiban  = days

        },
        async joinac(){
          const id = this.$route.query.id;
          const uid = this.$store.state.userInfo.uid;


          // 如果包含有地址信息的话
          if(this.options.find(r=>r.type=='地址')){
            this.options.find(r=>r.type=='地址').value = this.areaStr + "," +this.detailAdd
          }

          const _acinfo =  this.options.map(r=>{
            const { keyname,value } = r;
            return {keyname,value:value?value:''}
          })


          console.log('the post data', this.options)
          // 做一下检测
          let isOk =  true
          let kname = ''


          this.options.forEach(r=>{
                if(r.required&&!r.value){
                  isOk = false
                  kname = r.keyname
                }
          })

          if(!isOk) return this.$toast.info('请记得填写'+kname)


          const activityInfo = JSON.stringify(_acinfo)
          let res = await  this.$api.post('/mapi/joinAc',{uid,id,activityInfo})
          const { success,data,info } = res.data
          if(!success) return this.$toast.failed(info)
          this.$toast.succeed('预约成功')
        }
    }
};
</script>

<style lang="scss" scoped>

@function vw($px) {
  @return ($px / 750) * 100vw;
}
html,body{
    margin: 0;
    padding: 0;
    height: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    .lt{
        float:left;
    }
    .rt{
        float:right;
    }
    .at{
        margin: 0 auto;
    }
    .hc{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .cr{
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
    }
    .dm{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translatey(-50%);
    }
    .ft1{
        font-size: vw(32);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;

    }
    .ft2{
        font-size: vw(32);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;

    }
    .ft3{
        font-size: vw(26);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;
    }
    .ft4{
        font-size: vw(24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;
    }
    .ft5{
        font-size: vw(24);
        transform: scale(.83);
        transform-origin: 0;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3c3c3c;
    }
    .iconfont{
        text-align:center;
        color: #3c3c3c;
    }

    .p7{
        height: 100%;
        width: vw(750);
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction:column;

        .hd{
            width:100%;
            height: 100px;
            background: #e5e5e5;

            .hdfb{
               height: vw(48);
               line-height: vw(48);
               padding:  vw(66) vw(30) vw(10);
               display: flex;
               justify-content:space-between;
               position: relative;
            }

        }
        .ibox{
            height: 0;
            flex-grow:1;
            overflow-y:scroll;
            .ibh{

                width: 100%;
                border-bottom: vw(10) solid #f4f4f4;
                .ibhi{
                    height: vw(442);
                    background-size:contain;
                    position: relative;
                    .ibhi>i{
                        position: absolute;
                    }
                    i:first-child {
                        font-size: vw(120);
                        opacity: 0.5;
                    }
                    i:last-child{
                        font-size: vw(40);
                        position: absolute;
                        bottom: vw(28);
                        right: vw(34);
                        color: #fff;
                    }
                }
                .ibhf{
                    height: vw(144);
                    padding: vw(20) vw(58);
                    .ibhft{
                        height: vw(60);
                        line-height: vw(30);
                        width: vw(520);
                    }
                    .ibhfb{
                        height: vw(28);
                        line-height: vw(28);
                        margin-top: vw(10) ;

                    }
                }


            }
            .ibi{
                padding-left: vw(30) ;

                .ibih{
                    height: vw(124);
                    line-height: vw(124);
                    padding-left: vw(24) ;
                }
                .ibii{
                    min-height: vw(636);

                    .img {
                        height: vw(450);
                        width: vw(686);
                        background-image:url(https://fakeimg.pl/686x450);
                        background-size:contain;
                    }
                    .txt{
                        height: vw(102);
                        line-height: vw(34);
                        margin-top: vw(34);
                        margin-right: vw(40) ;


                    }

                }


            }





        }
        .bbox{
            height: vw(75);
            line-height: vw(75);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content:space-around;
            padding-bottom: 10px;

          &>div{
            width: 90%;
            margin:0 auto;
            text-align: center;
            background: red;
            border-radius: 10px;
            color:#fff
          }


        }




    }
  .holder{
    .ip{
      width: 180px;
    }
  }

}

::v-deep{
  .md-cell-item-body{
    padding-top:5px;
    padding-bottom:5px
  }
}
</style>
